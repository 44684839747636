import React from 'react';
import styled from 'styled-components';
import { Body2, H1, H2, H3, P } from '@/components/Typography';
import ButtonDefault from '@/components/ButtonDefault';
import page2viewport1 from '../../assets/img/toolkitHomeVp1.png';
import viewport4img from '../../assets/img/orderingTerminalAppsVp1.png';
import viewport6img from '../../assets/img/toolkitHomeVp6.png';
import viewport7Img from '../../assets/img/cbdVp8Bg.png';
import Section from '@/components/Section';
import FirstViewport from '@/components/FirstViewport';
import Carousel from '@/components/Carousel';
import CbdPluginCard from '@/components/CbdPluginCard';
import { ToolkitHomePageContent } from '@/pages';
import ReversibleCardsComponent from '@/components/ReversibleCardsComponent';
import TickedItems from '@/components/TickedItems';
import KeyFutureCard from '@/components/KeyFutureCard';
import FirstViewportMirrored from '@/components/FirstViewportMirrored';

const FirstVpWrapper = styled.div`
  background: linear-gradient(118.47deg, #f8f9fa 3.57%, #e4e6f0 100%);
`;

const SecondViewport = styled(Section)`
  background-color: #ffff;
  @media (max-width: 1200px) {
    padding-bottom: 1rem;
  }
`;

const TwelfthViewport = styled(Section)`
  padding: 5rem calc((100vw - 1500px) / 2);
  padding-bottom: 10rem;
  background-color: rgba(194, 206, 219, 0.05);
  background-image: ${(props) => `url(${props.resource})`};
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 1200px) {
    padding: 5rem 2rem;
  }
`;

const StyledDescription = styled(P)`
  margin: 1.5rem 0 2.75rem;
  color: #495b6c;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
`;

const ViewportsWrapper = styled.div`
  background-color: #fff;
`;

const StyledH2 = styled(H2)`
  text-align: center;
  font-size: 26px;
  font-weight: 700;
  line-height: 40px;
`;

const DefaultViewport = styled(SecondViewport)<{
  withoutPaddingTop?: boolean;
  withoutPaddingBottom?: boolean;
}>`
  background: transparent;
  ${({ withoutPaddingTop }) => withoutPaddingTop && `padding-top: 0;`}
  ${({ withoutPaddingBottom }) => withoutPaddingBottom && `padding-bottom: 0;`}
`;

const GradientViewport = styled(DefaultViewport)`
  padding: 0;
  background: linear-gradient(
    180deg,
    rgba(194, 206, 219, 0.5) -46.34%,
    rgba(244, 247, 249, 0.5) 26.53%,
    #fff 100%
  );
`;

const PluginBody2 = styled(Body2)`
  text-align: center;
  margin-top: 3.2rem;

  @media (max-width: 1200px) {
    :nth-of-type(2n) {
      margin-top: 3rem;
    }
    :nth-of-type(3n) {
      margin-top: 3rem;
    }
  }
`;

const PluginCardWrapper = styled.div`
  display: flex;
  margin-top: 1.5rem;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;

  :nth-of-type(3n) {
    gap: 32px;
  }
`;

const DescriptionOne = styled(P)`
  color: #495b6c;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  margin: 26px 0 40px;
`;

const DescriptionTwo = styled(P)`
  color: #13273f;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 1rem;
`;

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 30px;
  margin-top: 40px;
`;

interface ToolkitHomeProps {
  content: ToolkitHomePageContent;
}

const ToolkitHomeContainer: React.FunctionComponent<ToolkitHomeProps> = ({
  content: {
    viewport1,
    viewport2,
    viewport3,
    viewport4,
    viewport5,
    viewport6,
    viewport7,
  },
}) => (
  <>
    <FirstVpWrapper>
      <FirstViewport imagePadding="0 0 0 7rem" img={page2viewport1}>
        <H1>
          {viewport1.title1}
          {` `}
          <span className="accent-text">{viewport1.title2}</span>
          {` `}
          {viewport1.title3}
        </H1>
        <StyledDescription>{viewport1.description}</StyledDescription>
        <ButtonDefault to={viewport1.button.url}>
          {viewport1.button.label}
        </ButtonDefault>
      </FirstViewport>
    </FirstVpWrapper>

    <SecondViewport>
      <H3>{viewport2.title}</H3>
      <Carousel imgMarginBottom="0.5rem" items={viewport2.carouselItems} />
    </SecondViewport>

    <ViewportsWrapper>
      <DefaultViewport>
        <ReversibleCardsComponent customGap="40px" cards={viewport3.cards} />
      </DefaultViewport>
    </ViewportsWrapper>
    <GradientViewport>
      <FirstViewport imagePadding="0 0 0 7rem" img={viewport4img}>
        <H1>
          <span className="accent-text">{viewport4.title1}</span>
          {` `}
          {viewport4.title2}
        </H1>
        <DescriptionOne>{viewport4.description1}</DescriptionOne>
        <DescriptionTwo>{viewport4.description2}</DescriptionTwo>
        <TickedItems items={viewport4.tickedDescription} />
      </FirstViewport>
    </GradientViewport>
    <DefaultViewport style={{ background: `#fff` }}>
      <H3
        style={{
          maxWidth: 768,
          margin: `0 auto`,
          textAlign: `center`,
          marginTop: -10,
        }}
      >
        {viewport5.title}
      </H3>
      <CardWrapper>
        {viewport5.cards.map((el) => (
          <KeyFutureCard label={el.label} />
        ))}
      </CardWrapper>
    </DefaultViewport>
    <ViewportsWrapper style={{ padding: `4rem 0 6rem` }}>
      <FirstViewportMirrored
        imagePadding="0 7rem 0 0"
        withRightPadding
        img={viewport6img}
      >
        <H1>
          <span className="accent-text">{viewport6.title1}</span>
          {` `}
          {viewport6.title2}
          {` `}
          <span className="accent-text">{viewport6.title3}</span>
          {` `}
          {viewport6.title4}
        </H1>
        <DescriptionOne>{viewport6.description1}</DescriptionOne>
        <TickedItems items={viewport6.tickedDescription} />
      </FirstViewportMirrored>
    </ViewportsWrapper>

    <TwelfthViewport resource={viewport7Img}>
      <StyledH2>{viewport7.title}</StyledH2>
      <PluginBody2>{viewport7.subtitle1}</PluginBody2>
      <PluginCardWrapper>
        {viewport7.eCommerceCards.map((item) => (
          <CbdPluginCard key={item.image} imageSrc={item.image} />
        ))}
      </PluginCardWrapper>
      <PluginBody2>{viewport7.subtitle2}</PluginBody2>
      <PluginCardWrapper>
        {viewport7.marketplaceCards.map((item) => (
          <CbdPluginCard key={item.image} imageSrc={item.image} />
        ))}
      </PluginCardWrapper>
      <PluginBody2>{viewport7.subtitle3}</PluginBody2>
      <PluginCardWrapper>
        {viewport7.accountancyCards.map((item) => (
          <CbdPluginCard
            maxWidthPercentage="70%"
            key={item.image}
            imageSrc={item.image}
          />
        ))}
      </PluginCardWrapper>
    </TwelfthViewport>
  </>
);

export default ToolkitHomeContainer;
