import React from 'react';
import Main from '../containers/Layout';
import toolkitHomeContent from '../../content/pages/home.yml';
import ToolkitHomeContainer from '@/containers/toolkit/Home';

export interface ToolkitHomePageContent {
  viewport1: {
    title1: string;
    title2: string;
    title3: string;
    description: string;
    button: {
      label: string;
      url: string;
    };
  };
  viewport2: {
    title: string;
    carouselItems: {
      title: string;
      text: string;
      image: string;
      link: string;
    }[];
  };
  viewport3: {
    cards: {
      title: string;
      greyText: string;
      tickedDescription: { item: string }[];
      image: string;
    }[];
  };

  viewport4: {
    title1: string;
    title2: string;
    description1: string;
    description2: string;
    tickedDescription: { item: string }[];
  };
  viewport5: {
    title: string;
    cards: { label: string }[];
  };
  viewport6: {
    title1: string;
    title2: string;
    title3: string;
    title4: string;
    description1: string;
    tickedDescription: { item: string }[];
  };
  viewport7: {
    title: string;
    subtitle1: string;
    eCommerceCards: {
      image: string;
    }[];
    subtitle2: string;
    marketplaceCards: {
      image: string;
    }[];
    subtitle3: string;
    accountancyCards: {
      image: string;
    }[];
  };
}

const Home: React.FunctionComponent = () => {
  const {
    viewport1,
    viewport2,
    viewport3,
    viewport4,
    viewport5,
    viewport6,
    viewport7,
  } = toolkitHomeContent as unknown as ToolkitHomePageContent;
  return (
    <Main>
      <ToolkitHomeContainer
        content={{
          viewport1,
          viewport2,
          viewport3,
          viewport4,
          viewport5,
          viewport6,
          viewport7,
        }}
      />
    </Main>
  );
};

export default Home;
